<template>
  <div>
    <a-card :bordered="false">
      <template #title>
        对局列表<i @click="refresh" style="cursor: pointer;margin-left: 15px" :class="loading ? 'el-icon-loading' : 'el-icon-refresh-right'"></i>
      </template>
      <div v-loading="loading" element-loading-background="#111111">
        <el-timeline v-if="matchList.length > 0" style="padding: 20px;">
          <el-timeline-item :timestamp="DateUtil.format(match['matchDateTime'])" placement="top" v-for="(match, index) in matchList" :key="index">
            <template #dot>
              <div style="background-color: #e5e5e5;width: 10px;height: 10px"></div>
            </template>
            <div @click="expandMatch(match)" style="background-color: rgba(89,89,89,0.2);font-size: 20px;cursor: pointer">
              <div class="match-container" style="width: 100%;height: 73px;color: white;display: flex;justify-content: space-between;">
                <!--地图图片、服务器名字等-->
                <div class="match-info-container" style="display: flex;justify-content: flex-start;">
                  <div class="mapImage">
                    <el-image style="width: 115px;height: 73px;" :src="Bf1Map[match['gameMap']]['image']"/>
                  </div>
                  <div style="width: 100%;margin-left: 10px;display: flex;flex-direction: column;justify-content: space-around;">
                    <div class="match-server-name-text" style="width: 100%;font-weight: 500;line-height: 20px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                      {{ match['serverName'] }}
                    </div>
                    <div class="match-server-info-text" style="width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                      {{ Bf1ServerType[match['serverType']] }} - {{ BF1Mode[match['gameMode']]['name'] }} - {{ Bf1Map[match['gameMap']]['name'] }}
                    </div>
                  </div>
                </div>
                <!--右侧对局结果-->
                <div class="match-result-container" style="display: flex;align-items: center;justify-content: flex-end;margin-right: 10px;">
                  <div style="display: flex;flex-direction: column;justify-content: space-around;align-items: end;">
                    <div class="match-result-text" :style="'color:' + dict[match['matchResult']]['color']">
                      {{ dict[match['matchResult']]['name'] }}
                    </div>
                    <div class="match-result-duration">
                      {{ DateUtil.toDurationMinutesString(match['matchActualDuration']) }}
                    </div>
                  </div>
                  <div style="margin-left: 10px">
                    <img class="match-team-image" :src="match['teamId'] === 0 ? Bf1Map[match['gameMap']]['teamOne']['image'] : Bf1Map[match['gameMap']]['teamTwo']['image']" width="53" height="53" alt="">
                  </div>
                </div>
              </div>

              <el-collapse-transition>
                <div v-if="match['expand']" style="border-top: 1px solid #8a8a8a">
                  <a-row>
                    <a-col :xs="firstDataXs" :sm="firstDataSm" :md="firstDataMd" :lg="firstDataLg" :xl="firstDataXl" :xxl="firstDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="first-data" title="击杀" :value="objectUtil.nullToDefault(match['kills'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="firstDataXs" :sm="firstDataSm" :md="firstDataMd" :lg="firstDataLg" :xl="firstDataXl" :xxl="firstDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="first-data" title="死亡" :value="objectUtil.nullToDefault(match['deaths'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="firstDataXs" :sm="firstDataSm" :md="firstDataMd" :lg="firstDataLg" :xl="firstDataXl" :xxl="firstDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="first-data" title="KD" :value="objectUtil.nullToDefault(match['kd'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="firstDataXs" :sm="firstDataSm" :md="firstDataMd" :lg="firstDataLg" :xl="firstDataXl" :xxl="firstDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="first-data" title="KPM" :value="objectUtil.nullToDefault(match['kpm'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="firstDataXs" :sm="firstDataSm" :md="firstDataMd" :lg="firstDataLg" :xl="firstDataXl" :xxl="firstDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="first-data" title="SPM" :value="objectUtil.nullToDefault(match['spm'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="firstDataXs" :sm="firstDataSm" :md="firstDataMd" :lg="firstDataLg" :xl="firstDataXl" :xxl="firstDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="first-data" title="总得分" :value="objectUtil.nullToDefault(match['score'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="secondaryDataXs" :sm="secondaryDataSm" :md="secondaryDataMd" :lg="secondaryDataLg" :xl="secondaryDataXl" :xxl="secondaryDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="secondary-data" title="命中率" :value="objectUtil.nullToDefault(match['accuracyRatio'], '—') + '%'"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="secondaryDataXs" :sm="secondaryDataSm" :md="secondaryDataMd" :lg="secondaryDataLg" :xl="secondaryDataXl" :xxl="secondaryDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="secondary-data" title="爆头率" :value="objectUtil.nullToDefault(match['headShotsRatio'], '—') + '%'"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="secondaryDataXs" :sm="secondaryDataSm" :md="secondaryDataMd" :lg="secondaryDataLg" :xl="secondaryDataXl" :xxl="secondaryDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="secondary-data" title="射击数" :value="objectUtil.nullToDefault(match['shots'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="secondaryDataXs" :sm="secondaryDataSm" :md="secondaryDataMd" :lg="secondaryDataLg" :xl="secondaryDataXl" :xxl="secondaryDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="secondary-data" title="命中数" :value="objectUtil.nullToDefault(match['hits'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="secondaryDataXs" :sm="secondaryDataSm" :md="secondaryDataMd" :lg="secondaryDataLg" :xl="secondaryDataXl" :xxl="secondaryDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="secondary-data" title="爆头数" :value="objectUtil.nullToDefault(match['headshots'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="secondaryDataXs" :sm="secondaryDataSm" :md="secondaryDataMd" :lg="secondaryDataLg" :xl="secondaryDataXl" :xxl="secondaryDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="secondary-data" title="协助击杀" :value="objectUtil.nullToDefault(match['killAssists'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="secondaryDataXs" :sm="secondaryDataSm" :md="secondaryDataMd" :lg="secondaryDataLg" :xl="secondaryDataXl" :xxl="secondaryDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="secondary-data" title="占点数" :value="objectUtil.nullToDefault(match['flagsCaptured'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="secondaryDataXs" :sm="secondaryDataSm" :md="secondaryDataMd" :lg="secondaryDataLg" :xl="secondaryDataXl" :xxl="secondaryDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="secondary-data" title="守点数" :value="objectUtil.nullToDefault(match['flagsDefended'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="secondaryDataXs" :sm="secondaryDataSm" :md="secondaryDataMd" :lg="secondaryDataLg" :xl="secondaryDataXl" :xxl="secondaryDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="secondary-data" title="救援数" :value="objectUtil.nullToDefault(match['revives'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="secondaryDataXs" :sm="secondaryDataSm" :md="secondaryDataMd" :lg="secondaryDataLg" :xl="secondaryDataXl" :xxl="secondaryDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="secondary-data" title="治疗数" :value="objectUtil.nullToDefault(match['heals'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="secondaryDataXs" :sm="secondaryDataSm" :md="secondaryDataMd" :lg="secondaryDataLg" :xl="secondaryDataXl" :xxl="secondaryDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic class="secondary-data" title="维修数" :value="objectUtil.nullToDefault(match['repairs'], '—')"/>
                      </a-card-grid>
                    </a-col>
                    <a-col :xs="secondaryDataXs" :sm="secondaryDataSm" :md="secondaryDataMd" :lg="secondaryDataLg" :xl="secondaryDataXl" :xxl="secondaryDataXxl">
                      <a-card-grid style="width: 100%;">
                        <a-statistic :value-style="{ color: match['skill'] < 0 ? '#71c02b' : match['skill'] > 0 ? '#f86d77' : '#ffffff' }" class="secondary-data" title="技巧值" :value="objectUtil.nullToDefault(match['skill'], '—')"/>
                      </a-card-grid>
                    </a-col>
                  </a-row>
                </div>
              </el-collapse-transition>
            </div>
          </el-timeline-item>
        </el-timeline>
        <div v-else style="height: 350px;color: white;display: flex;justify-content: center;align-items: center;font-size: 20px">
          暂无对局数据
        </div>
        <a-row v-if="total > 10" type="flex" justify="end" style="margin-bottom: 20px;">
          <a-col :span="24" style="display: flex;justify-content: center;margin-bottom: 10px;">
            <a-pagination :page-size="10" :total="total" @change="pageChange"/>
          </a-col>
          <a-col :span="24" style="display: flex;justify-content: center">
            <div style="line-height: 30px;color: white">共 {{total}} 场对局</div>
          </a-col>
        </a-row>
      </div>
    </a-card>
  </div>
</template>

<script>

import {DateUtil} from "../utils/date-util";
import {Bf1Map, BF1Mode, Bf1ServerType} from "../enum/bf1-enum";
import {objectUtil} from "../utils/object-util";

export default {
  name: "matchList",
  computed: {
    objectUtil() {
      return objectUtil
    },
    DateUtil() {
      return DateUtil
    },
  },
  props: {
    matchList: {
      type: Array,
      default: () => []
    },
    pageNumber: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    loading: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      Bf1ServerType: Bf1ServerType,
      BF1Mode: BF1Mode,
      Bf1Map: Bf1Map,
      
      firstDataXxl: 4,
      firstDataXl: 4,
      firstDataLg: 8,
      firstDataMd: 8,
      firstDataSm: 8,
      firstDataXs: 12,

      secondaryDataXxl: 2,
      secondaryDataXl: 4,
      secondaryDataLg: 6,
      secondaryDataMd: 6,
      secondaryDataSm: 6,
      secondaryDataXs: 12,

      dict: {
        1: {
          name: '胜利',
          color: 'rgb(255, 205, 0)'
        },
        2: {
          name: '失败',
          color: 'rgb(252, 118, 118)'
        },
        3: {
          name: '平局',
          color: 'rgb(156,204,245)'
        },
        4: {
          name: '离开',
          color: 'rgb(255, 255, 255)'
        },
      }
    }
  },
  methods: {
    pageChange(value) {
      this.$emit("page-change", value)
    },
    expandMatch(match) {
      if (match['expand'] === null) {
        this.$set(match, 'expand', true)
      }
      else {
        this.$set(match, 'expand', !match['expand'])
      }
    },
    getServerTypeTextColor(serverType) {
      if (serverType === 1) {
        return '#98FB98'
      }
      return '#87CEFF'
    },
    refresh() {
      if (this.loading) {
        return
      }
      this.$emit("refresh")
    },
  }
}
</script>

<style scoped>
/** a-card卡片黑色风格 **/
.ant-card {
  background: rgba(17,17,17,0.7);
}
::v-deep .ant-card-head {
  color: white;
  background-color: #1c1c1c;
  border-bottom: none;
}
::v-deep .ant-card-body {
  padding: 0;
}
.ant-card-grid {
  box-shadow: none;
}
::v-deep .ant-card-head-title {
  font-size: 20px;
}



::v-deep .el-timeline-item__tail {
  border-left: 2px solid #e5e5e5;
}

::v-deep .el-timeline-item__timestamp {
  color: white;
  font-size: 15px;
}

::v-deep .ant-pagination-prev>.ant-pagination-item-link,
::v-deep .ant-pagination-next>.ant-pagination-item-link{
  background-color: rgba(89,89,89,0.2);
  border: none;
  color: white;
}
::v-deep .ant-pagination-prev>.ant-pagination-item-link:hover,
::v-deep .ant-pagination-next>.ant-pagination-item-link:hover{
  background-color: rgb(100, 100, 100);
}
::v-deep .ant-pagination-disabled.ant-pagination-prev>.ant-pagination-item-link,
::v-deep .ant-pagination-disabled.ant-pagination-prev>.ant-pagination-item-link:hover,
::v-deep .ant-pagination-disabled.ant-pagination-next>.ant-pagination-item-link,
::v-deep .ant-pagination-disabled.ant-pagination-next>.ant-pagination-item-link:hover{
  color: #3d3d3d;
  background-color: #282828;;
}

::v-deep .ant-pagination-item-active {
  border: none;
  background-color: rgb(126, 124, 124) !important;
}
::v-deep .ant-pagination-item,
::v-deep .ant-pagination-jump-next,
::v-deep .ant-pagination-jump-prev{
  border: none;
  background-color: rgba(89,89,89,0.2);
}
::v-deep .ant-pagination-item:hover {
  background-color: rgb(100, 100, 100);
}
::v-deep .ant-pagination-item a,
::v-deep .ant-pagination-jump-next .ant-pagination-item-ellipsis,
::v-deep .ant-pagination-jump-next .anticon-double-right,
::v-deep .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
::v-deep .ant-pagination-jump-prev .anticon-double-left{
  color: white;
}


/** <576px **/
@media screen and (max-width: 576px) {
  .mapImage {
    display: none;
  }
  .match-team-image {
    display: none;
  }
  .match-info-container {
    width: 60%;
  }
  .match-result-container {
    width: 30%;
  }
  .match-server-name-text {
    font-size: 17px;
  }
  .match-server-info-text {
    font-size: 12px;
  }
  .match-result-text,
  .match-result-duration{
    font-size: 18px;
  }
  ::v-deep .first-data>.ant-statistic-title,
  ::v-deep .first-data>.ant-statistic-content,
  ::v-deep .first-data>.ant-statistic-content .ant-statistic-content-value-decimal{
    font-size: 20px;
  }
  ::v-deep .secondary-data>.ant-statistic-title,
  ::v-deep .secondary-data>.ant-statistic-content,
  ::v-deep .secondary-data>.ant-statistic-content .ant-statistic-content-value-decimal{
    font-size: 13px;
  }
}

/** ≥576px **/
@media screen and (min-width: 576px) {
  .mapImage {
    display: none;
  }
  .match-info-container {
    width: 60%;
  }
  .match-result-container {
    width: 40%;
  }
  .match-server-name-text,
  .match-server-info-text{
    font-size: 16px;
  }
  ::v-deep .first-data>.ant-statistic-title,
  ::v-deep .first-data>.ant-statistic-content,
  ::v-deep .first-data>.ant-statistic-content .ant-statistic-content-value-decimal{
    font-size: 22px;
  }
  ::v-deep .secondary-data>.ant-statistic-title,
  ::v-deep .secondary-data>.ant-statistic-content,
  ::v-deep .secondary-data>.ant-statistic-content .ant-statistic-content-value-decimal{
    font-size: 13px;
  }
}
/** ≥768px **/
@media screen and (min-width: 642px) {
  .mapImage {
    display: none;
  }
  .match-info-container {
    width: 70%;
  }
  .match-result-container {
    width: 30%;
  }
  .match-server-name-text,
  .match-server-info-text{
    font-size: 18px;
  }
  ::v-deep .first-data>.ant-statistic-title,
  ::v-deep .first-data>.ant-statistic-content,
  ::v-deep .first-data>.ant-statistic-content .ant-statistic-content-value-decimal{
    font-size: 23px;
  }
  ::v-deep .secondary-data>.ant-statistic-title,
  ::v-deep .secondary-data>.ant-statistic-content,
  ::v-deep .secondary-data>.ant-statistic-content .ant-statistic-content-value-decimal{
    font-size: 15px;
  }
}
/** ≥768px **/
@media screen and (min-width: 768px) {
  .mapImage {
    display: block;
  }
  .match-info-container {
    width: 50%;
  }
  .match-result-container {
    width: 30%;
  }
  .match-server-name-text,
  .match-server-info-text{
    font-size: 20px;
  }
  ::v-deep .first-data>.ant-statistic-title,
  ::v-deep .first-data>.ant-statistic-content,
  ::v-deep .first-data>.ant-statistic-content .ant-statistic-content-value-decimal{
    font-size: 24px;
  }
  ::v-deep .secondary-data>.ant-statistic-title,
  ::v-deep .secondary-data>.ant-statistic-content,
  ::v-deep .secondary-data>.ant-statistic-content .ant-statistic-content-value-decimal{
    font-size: 15px;
  }
}
/** ≥992px **/
@media screen and (min-width: 992px) {
  .mapImage {
    display: block;
  }
  .match-info-container {
    width: 60%;
  }
  .match-result-container {
    width: 30%;
  }
  ::v-deep .first-data>.ant-statistic-title,
  ::v-deep .first-data>.ant-statistic-content,
  ::v-deep .first-data>.ant-statistic-content .ant-statistic-content-value-decimal{
    font-size: 25px;
  }
  ::v-deep .secondary-data>.ant-statistic-title,
  ::v-deep .secondary-data>.ant-statistic-content,
  ::v-deep .secondary-data>.ant-statistic-content .ant-statistic-content-value-decimal{
    font-size: 15px;
  }
}
/** ≥1200px **/
@media screen and (min-width: 1200px) {
  .mapImage {
    display: block;
  }
  .match-info-container {
    width: 70%;
  }
  .match-result-container {
    width: 30%;
  }
}
/** ≥1600px **/
@media screen and (min-width: 1600px) {

}
</style>