<template>
  <div>
    <a-card :bordered="false">
      <template #title>
        武器列表<i @click="refresh" style="cursor: pointer;margin-left: 15px" :class="loading ? 'el-icon-loading' : 'el-icon-refresh-right'"></i>
      </template>
      <div v-loading="loading" element-loading-background="#111111" style="padding: 20px">
        <div v-if="weaponList.length > 0">
          <a-row :gutter="[5, 5]" style="margin-bottom: 20px" type="flex" justify="space-between">
            <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" :xxl="16">
              <a-row :gutter="[5, 5]" type="flex" justify="space-between">
                <a-col :xs="6" :sm="4" :md="4" :lg="4" :xl="2" :xxl="2" v-for="(filter, index) in filters" :key="index">
                  <el-tag color="rgba(0,0,0,0)" type="info" :effect="filterValue.has(filter['value']) ? 'dark' : 'plain'" @click="handleTagClick(filter)" style="width: 100%;text-align: center;cursor: pointer">{{ filter['name'] }}</el-tag>
                </a-col>
              </a-row>
            </a-col>
            <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" :xxl="5">
              <el-input @input="handleFilter" class="transparentElement" v-model="filterKeyword" size="small" placeholder="输入关键字搜索"/>
            </a-col>
          </a-row>
          <div v-for="(weapon, index) in displayWeaponList" :key="index" style="padding: 10px;background-color: rgba(89,89,89,0.2);color: white;display: flex;justify-content: space-between" :style="'margin-top:' + (index > 0 ? '20px' : '0')">
            <!--武器图片和名字-->
            <a-row type="flex" justify="space-between" style="width: 100%;">
              <a-col :xs="24" :sm="16" :md="12" :lg="8" :xl="6" :xxl="5" style="display: flex;flex-direction: column;justify-content: space-around;align-items: center">
                <el-image style="margin-bottom: 10px;width: 180px;height: 45px" :src="weapon['image']"/>
                <div style="font-size: 18px;font-weight: 500;line-height: 20px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                  {{ weapon['name'] }}
                </div>
              </a-col>
              <a-col :xs="24" :sm="8" :md="4" :lg="4" :xl="4" :xxl="4">
                <a-card-grid style="width: 100%;">
                  <a-statistic class="baseData" title="类型" :value="objectUtil.nullToDefault(weapon['category'], '—')"/>
                </a-card-grid>
              </a-col>
              <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="14" :xxl="15">
                <a-row>
                  <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                    <a-card-grid style="width: 100%;">
                      <a-statistic class="baseData" title="击杀" :value="objectUtil.nullToDefault(weapon['kills'], '—')"/>
                    </a-card-grid>
                  </a-col>
                  <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                    <a-card-grid style="width: 100%;">
                      <a-statistic class="baseData" title="KPM" :value="objectUtil.nullToDefault(weapon['kpm'], '—')"/>
                    </a-card-grid>
                  </a-col>
                  <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                    <a-card-grid style="width: 100%;">
                      <a-statistic class="baseData" title="爆头率" :value="objectUtil.nullToDefault(weapon['headShotsRatio'], '—')"/>
                    </a-card-grid>
                  </a-col>
                  <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                    <a-card-grid style="width: 100%;">
                      <a-statistic class="baseData" title="命中率" :value="objectUtil.nullToDefault(weapon['accuracyRatio'], '—')"/>
                    </a-card-grid>
                  </a-col>
                  <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                    <a-card-grid style="width: 100%;">
                      <a-statistic class="baseData" :value="objectUtil.nullToDefault(weapon['efficiency'], '—')">
                        <template #title>
                          效率
                          <a-tooltip v-if="index == 0" placement="topLeft" title="命中数/击杀数" arrow-point-at-center>
                            <a-icon style="font-size: 16px" type="question-circle" />
                          </a-tooltip>
                        </template>
                      </a-statistic>
                    </a-card-grid>
                  </a-col>
                  <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                    <a-card-grid style="width: 100%;">
                      <a-statistic class="baseData" title="时长" :value="objectUtil.nullToDefault(weapon['hoursPlayed'], '—') + '小时'"/>
                    </a-card-grid>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
          <div v-if="displayWeaponOffset < filterWeaponList.length" style="margin-top: 20px">
            <a-button @click="loadMore" style="height: 40px;" size="large" class="hoverButton" ghost block>加载更多武器</a-button>
          </div>
        </div>
        <div v-else style="height: 350px;color: white;display: flex;justify-content: center;align-items: center;font-size: 20px">
          暂无武器数据
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>

import {objectUtil} from "../utils/object-util";

export default {
  name: "weaponList",
  computed: {
    objectUtil() {
      return objectUtil
    }
  },
  props: {
    weaponList: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    weaponList (val) {
      if (val.length > 0) {
        this.handleFilter()
      }
    }
  },
  data() {
    return {
      xxl: 4,
      xl: 4,
      lg: 4,
      md: 4,
      sm: 8,
      xs: 8,
      displayWeaponOffset: 10,
      displayWeaponList: [],
      filterValue: new Set(["冲锋枪", "霰弹枪", "半自动步枪", "轻机枪", "步枪", "佩枪", "近战武器", "手榴弹", "配备", "驾驶员", "菁英兵", "制式步枪"]),
      filterWeaponList: [],
      filterKeyword: '',
      filters: [
        {
          name: "冲锋枪",
          value: "冲锋枪",
        },
        {
          name: "霰弹枪",
          value: "霰弹枪",
        },
        {
          name: "半自动",
          value: "半自动步枪",
        },
        {
          name: "轻机枪",
          value: "轻机枪",
        },
        {
          name: "狙击枪",
          value: "步枪",
        },
        {
          name: "手枪",
          value: "佩枪",
        },
        {
          name: "近战",
          value: "近战武器",
        },
        {
          name: "手榴弹",
          value: "手榴弹",
        },
        {
          name: "配备",
          value: "配备",
        },
        {
          name: "驾驶员",
          value: "驾驶员",
        },
        {
          name: "菁英兵",
          value: "菁英兵",
        },
        {
          name: "制式步枪",
          value: "制式步枪",
        }
      ]
    }
  },
  methods: {
    handleTagClick(filter) {
      if (this.filterValue.has(filter['value'])) {
        this.filterValue.delete(filter['value'])
      }
      else {
        this.filterValue.add(filter['value'])
      }
      this.handleFilter();
    },
    handleFilter() {
      this.filterWeaponList = this.weaponList.filter(i => this.filterValue.has(i['category']));
      if (this.filterKeyword) {
        this.filterWeaponList = this.filterWeaponList.filter(i => this.filterKeyword && i['name'].toLowerCase().includes(this.filterKeyword.toLowerCase()))
      }
      this.displayWeaponOffset = 10;
      this.displayWeaponList = this.filterWeaponList.slice(0, this.displayWeaponOffset)
    },
    refresh() {
      if (this.loading) {
        return
      }
      this.$emit("refresh")
    },
    loadMore() {
      this.displayWeaponOffset += 10
      this.displayWeaponList = this.filterWeaponList.slice(0, this.displayWeaponOffset)
    }
  }
}
</script>

<style scoped>
/* 透明输入框 */
::v-deep .transparentElement .el-input__inner {
  /* 使input框的背景变透明 */
  background-color: transparent;
  /* 使边框也变透明 */
  border-color: white;
  /* 改变获取焦点后的竖线颜色 */
  caret-color: white;
  color:#FFFFFF;
}
/* 透明输入框聚焦 */
::v-deep .transparentElement .el-input__inner:focus {
  border-color: #e6a23c;
}
::v-deep .el-input .el-input__count .el-input__count-inner {
  background-color: transparent;
  color: white;
}


.ant-card {
  background: rgba(17,17,17,0.7);
}
::v-deep .ant-card-head {
  color: white;
  background-color: #1c1c1c;
  border-bottom: none;
}
::v-deep .ant-card-body {
  padding: 0;
}
.ant-card-grid {
  box-shadow: none;
}
::v-deep .ant-card-head-title {
  font-size: 20px;
}


.hoverButton.ant-btn:hover, .hoverButton.ant-btn:focus {
  background-color: white !important;
  border-color: white !important;
  font-weight: bold;
  color: #4f4f4f;
}
.hoverButton.ant-btn[disabled]:hover, .hoverButton.ant-btn[disabled]:focus {
  color: #4f4f4f;
}
.hoverButton.ant-btn[disabled] {
  color: white;
}

::v-deep .ant-card-grid {
  padding: 0;
}
.el-tag--dark.el-tag--info {
  border-color: #b3b3b3;
}
.el-tag--plain.el-tag--info {
  border-color: #5b5b5b;
}


/** <576px **/
@media screen and (max-width: 576px) {
  ::v-deep .ant-statistic-content {
    font-size: 18px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 18px;
  }
  ::v-deep .ant-statistic-title {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 5px;
  }
}

/** ≥576px **/
@media screen and (min-width: 576px) {
  ::v-deep .ant-statistic-content {
    font-size: 20px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 20px;
  }
  ::v-deep .ant-statistic-title {
    font-size: 18px;
  }
}
/** ≥768px **/
@media screen and (min-width: 642px) {
  ::v-deep .ant-statistic-content {
    font-size: 22px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 22px;
  }
  ::v-deep .ant-statistic-title {
    font-size: 20px;
  }
}
/** ≥768px **/
@media screen and (min-width: 768px) {
  ::v-deep .ant-statistic-content {
    font-size: 22px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 22px;
  }
  ::v-deep .ant-statistic-title {
    font-size: 20px;
  }
}
/** ≥992px **/
@media screen and (min-width: 992px) {
  ::v-deep .ant-statistic-content {
    font-size: 22px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 22px;
  }
  ::v-deep .ant-statistic-title {
    font-size: 20px;
  }
}
/** ≥1200px **/
@media screen and (min-width: 1200px) {
  ::v-deep .baseData>.ant-statistic-content{
    font-size: 28px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 28px;
  }
  ::v-deep .baseData>.ant-statistic-title {
    font-size: 18px;
    margin-top: 5px;
  }
}
/** ≥1600px **/
@media screen and (min-width: 1600px) {
  ::v-deep .baseData>.ant-statistic-content {
    font-size: 28px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 28px;
  }
  ::v-deep .baseData>.ant-statistic-title {
    font-size: 18px;
  }
}
</style>